import React from 'react';

import { FooterView } from './footer/FooterView';
import { HeaderView } from './header/HeaderView';
import styles from './LayoutView.module.scss';

export const LayoutView = ({ children }: any) => {
  return (
    <main className={styles.body}>
      <HeaderView />
      {children}
      <FooterView />
    </main>
  );
};
